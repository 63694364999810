.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.Toastify .Toastify__toast-container {
    z-index: 99999999 !important;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.application-loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: fixed !important;
    z-index: 1000;
    text-align: center;
    background-color: black;
    z-index: 99999999;
    opacity: 0.4;
    top: 0;
}

.application-loading-initial {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: fixed !important;
    z-index: 1000;
    text-align: center;
    background-color: #ffff;
    z-index: 20000;
    /* opacity: 0.9; */
    top: 0;
    padding: 20px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.closeModal {
    background: transparent;
    border: none;
    margin: 5px 5px 0px 0px;
    font-weight: 600;
}

.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #1168B4;
    color: #1168B4;
    box-shadow: 9999px 0 0 -5px #1168B4;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #1168B4;
    color: #1168B4;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #1168B4;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #1168B4;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #1168B4;
    }

    30% {
        box-shadow: 9984px 0 0 2px #1168B4;
    }

    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #1168B4;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #1168B4;
    }

    30% {
        box-shadow: 9999px 0 0 2px #1168B4;
    }

    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #1168B4;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #1168B4;
    }

    30% {
        box-shadow: 10014px 0 0 2px #1168B4;
    }

    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #1168B4;
    }
}

.dot-pulse-btn {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FFFFFF;
    color: #FFFFFF;
    box-shadow: 9999px 0 0 -5px #FFFFFF;
    animation: dotPulseGo 1.5s infinite linear;
    animation-delay: .25s;
}

.dot-pulse-btn::before,
.dot-pulse-btn::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FFFFFF;
    color: #FFFFFF;
}

.dot-pulse-btn::before {
    box-shadow: 9984px 0 0 -5px #FFFFFF;
    animation: dotPulseStart 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse-btn::after {
    box-shadow: 10014px 0 0 -5px #FFFFFF;
    animation: dotPulseLater 1.5s infinite linear;
    animation-delay: .5s;
}

@keyframes dotPulseStart {
    0% {
        box-shadow: 9984px 0 0 -5px #FFFFFF;
    }

    30% {
        box-shadow: 9984px 0 0 2px #FFFFFF;
    }

    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #FFFFFF;
    }
}

@keyframes dotPulseGo {
    0% {
        box-shadow: 9999px 0 0 -5px #FFFFFF;
    }

    30% {
        box-shadow: 9999px 0 0 2px #FFFFFF;
    }

    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #FFFFFF;
    }
}

@keyframes dotPulseLater {
    0% {
        box-shadow: 10014px 0 0 -5px #FFFFFF;
    }

    30% {
        box-shadow: 10014px 0 0 2px #FFFFFF;
    }

    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #FFFFFF;
    }
}

.accept-row {
    position: fixed;
    margin-right: 0 !important;
    bottom: 0px;
    padding-bottom: 7px;
    background: #ffffff;
}

.lenders-values {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
}

.lenders-props {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    letter-spacing: 0.05em;

}
.lender-name {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
}