@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://use.typekit.net/mwc2izh.css');

@font-face {
    font-family: 'Avenir Next';
    src: url('../src/assets/fonts/avenir400.ttf') format('truetype'), url('../src/assets/fonts/avenir500.ttf') format('truetype'), url('../src/assets/fonts/avenir600.ttf') format('truetype'), url('../src/assets/fonts/avenir800.ttf') format('truetype');
}

body {
    font-family: 'Nunito';
}

/* p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
div,
span,
button,
.btn,
.link-btn {
    font-family: 'Nunito';
} */


/* body {
    margin: 0;
    font-family: 'Muli';
} */

code {
    font-family: 'Muli';
}


/* common css */


/*----------------------------------------------------------------------- Font Size -------------------------------------------------------------*/

.fs-8 {
    font-size: 8px;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 24px;
}

.fs-30 {
    font-size: 30px;
}

.fs-48 {
    font-size: 48px;
}


/* ------------------------------------------------------------------------ Font Weight --------------------------------------------------------*/

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}


/* ----------------------------------------------------------------------- margin right -------------------------------------------------------- */

.me-6 {
    margin-right: 60px;
}

.me-7 {
    margin-right: 70px;
}

.me-8 {
    margin-right: 80px;
}

.me-9 {
    margin-right: 90px;
}

.me-10 {
    margin-right: 100px;
}


/* ----------------------------------------------------------------------- Margin left ----------------------------------------------------------------------- */

.ms-6 {
    margin-left: 60px;
}

.ms-7 {
    margin-left: 70px;
}

.ms-8 {
    margin-left: 80px;
}

.ms-9 {
    margin-left: 90px;
}

.ms-10 {
    margin-left: 100px;
}


/* ----------------------------------------------------------------------- Margin top ----------------------------------------------------------------------- */

.mt-6 {
    margin-top: 60px !important;
}

.mt-7 {
    margin-top: 70px !important;
}

.mt-8 {
    margin-top: 80px !important;
}

.mt-9 {
    margin-top: 90px !important;
}

.mt-10 {
    margin-top: 100px !important;
}


/* ----------------------------------------------------------------------- Margin bottom ----------------------------------------------------------------------- */

.mb-6 {
    margin-bottom: 60px;
}

.mb-7 {
    margin-bottom: 70px;
}

.mb-8 {
    margin-bottom: 80px;
}

.mb-9 {
    margin-bottom: 90px;
}

.mb-10 {
    margin-bottom: 100px;
}


/* ----------------------------------------------------------------------- padding right ----------------------------------------------------------------------- */

.pe-6 {
    padding-right: 60px;
}

.pe-7 {
    padding-right: 70px;
}

.pe-8 {
    padding-right: 80px;
}

.pe-9 {
    padding-right: 90px;
}

.pe-10 {
    padding-right: 100px;
}


/* ----------------------------------------------------------------------- padding left ----------------------------------------------------------------------- */

.ps-6 {
    padding-left: 60px;
}

.ps-7 {
    padding-left: 70px;
}

.ps-8 {
    padding-left: 80px;
}

.ps-9 {
    padding-left: 90px;
}

.ps-10 {
    padding-left: 100px;
}


/* ----------------------------------------------------------------------- padding top ----------------------------------------------------------------------- */

.pt-6 {
    padding-top: 60px;
}

.pt-7 {
    padding-top: 70px;
}

.pt-8 {
    padding-top: 80px;
}

.pt-9 {
    padding-top: 90px;
}

.pt-10 {
    padding-top: 100px;
}


/* ----------------------------------------------------------------------- padding bottom ----------------------------------------------------------------------- */

.pb-6 {
    padding-bottom: 60px;
}

.pb-7 {
    padding-bottom: 70px;
}

.pb-8 {
    padding-bottom: 80px;
}

.pb-9 {
    padding-bottom: 90px;
}

.pb-10 {
    padding-bottom: 100px;
}

.header-color {
    background: #E7F3FE;
}

.bank-logo {
    height: 50px;
    /* width: 116px; */
}

.border-circle {
    border-radius: 50%;
}

.hr-line {
    height: 1px;
    background-color: lightgrey;
}

.cyan-blue {
    background: #017aff !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.heading-text {
    color: #191c1f !important;
}

.normal-text {
    color: #757779 !important;
}

.bleu-text-color {
    color: #0e65d7 !important;
}

.link-btn {
    background: transparent;
    border: none;
}

.form-control {
    font-size: 16px !important;
}

.bootstrap-select {
    height: 45px !important;
}

.selectpicker {
    height: 45px !important;
    font-size: 16px !important;
    width: 25% !important;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 10px;
}

.inner {
    min-height: 200px !important;
}

.blue-bg-color {
    background-color: #13397F !important;
}

.select-account {
    margin-top: 40px !important;
}

.form-check-input:checked[type=radio] {
    background-image: url('../src/assets/check.png') !important;
}
.form-check-input-sm:checked[type=radio] {
    background-image: url('../src/assets/check.png') !important;
}
.selected-circle {
    background-image: url('../src/assets/check.png') !important;
}

.form-check-input {
    width: 24px !important;
    height: 25px !important;
}
.form-check .form-check-input-sm {
    float: left;
    margin-left: -1.5em;
}
.form-check-input-sm {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
    -webkit-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    width: 20px !important;
    height: 20px !important;
}
.form-check-input:checked {
    background-color: transparent !important;
    border-color: aliceblue !important;
}
.form-check-input-sm:checked {
    background-color: transparent !important;
    border-color: aliceblue !important;
}
.centervs {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .header-section {
        margin-top: 0px !important;
        background: #E7F3FE;
        border: 0px;
        border-radius: 0px;
    }
    .bank-logo {
        height: 50px;
    }
    .finvu-logo {
        height: 50px;
    }
    .selectpicker {
        width: 100% !important;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) and (max-width: 767px) {
    .header-section {
        margin-top: 50px !important;
    }
    .selectpicker {
        width: 50% !important;
    }
    .bank-logo-block {
        height: 80px !important;
        width: 80px !important;
    }
    .bank-image {
        width: 60px !important;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-section {
        margin-top: 50px !important;
    }
    .selectpicker {
        width: 50% !important;
    }
    .bank-logo-block {
        height: 80px !important;
        width: 80px !important;
    }
    .bank-image {
        width: 60px !important;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
    .bank-logo-block {
        height: 80px !important;
        width: 80px !important;
    }
    .bank-image {
        width: 60px !important;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .bank-logo-block {
        height: 80px !important;
        width: 80px !important;
    }
    .bank-image {
        width: 60px !important;
    }
}

.react-select-9-listbox {
    text-align: left !important;
}

.text-gray-800 {
    color: #384c74!important;
}